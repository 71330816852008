/* Navbar Css */
nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
  }
  
  nav.navbar.scrolled {
    padding: 10px 0;
    /* background-color: white; */
    background-color: #2a32d2;
    box-shadow: 0 0 10px 4px rgba(0,0,0,0.6);
  }
  
  nav.navbar a.navbar-brand {
      width: 9%;
      color: #111447 !important;
      font-size: 20px;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    /* color: #2a32d2 !important; */
    color: white;
    font-weight: bold;
    letter-spacing: 0.8px;
    padding: 0 15px;
    font-size: 18px;
    opacity: 0.75;
  }
  
  nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
  nav.navbar .navbar-nav a.nav-link.navbar-link.active {
      opacity: 1;
      font-weight: 500;
  }
  
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
      outline: none;
    box-shadow: none;
  }
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
  }
  nav.navbar .navbar-toggler-icon:focus {
      border-bottom: 2px solid #fff;
  }
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }
  nav.navbar .navbar-toggler-icon:after {
      top: 8px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      transform: rotate(45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
  } 
  
  