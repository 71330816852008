.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(80, 59, 59, 0.5);
  border-radius: 15px;
  color: black;
  padding: 15px 24px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.submit-btn {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
 
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact-info {
  background: rgba(77, 49, 49, 0.1) !important;
  color: #0b0101 !important;
  font-weight: 400;
  font-size: 18px;
  /* margin-top: 25%; */
  /* top:23%; */
  letter-spacing: 0.8px;
  position: relative;
  /* height: 200px;
  width: 200px; */

}

.contact-info img {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 210px;
  width: 210px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #0e0101;
}

.contact-info a:hover, .about a:hover {
  transform: scale(1.1);
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 143, 143, 0.5); /* Adjust the color and opacity here */
  z-index: 1; /* Ensure the overlay is above the image */
}

.image {
  display: block;
  width: 100%;
  height: auto;
  z-index: 2; /* Ensure the image is on top of the overlay */
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

.photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Set the height of the container */
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 143, 143, 0.5); 
  z-index: 1; 
}

h2 {
  color: #111447;
  text-align: center;
  margin-bottom: 40px;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000; 
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}








