/* Skills.css */
.skills-section {
    padding: 1rem;
    /* background-color: #3d4453; */
    color: white;
    text-align: center;
}

.skills-section h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #2a32d2;
}

.skills-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

/* .skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e1e1e;
    padding: 1rem;
    border-radius: 8px;
    transition: transform 0.2s;
} */

.skill-item:hover {
    transform: scale(1.05);
}

.skill-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
}

/* .skill-item p {
    margin: 0;
    font-size: 1rem;
    color: #ffc107;
} */

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #2a2222; */
    padding: 1rem;
    border-radius: 8px;
    transition: transform 0.2s;
    color: rgb(53, 43, 43);
    font-weight: bold;
}

